import { render, staticRenderFns } from "./settings.html?vue&type=template&id=f9f831f8&scoped=true&external"
import script from "./settings.ts?vue&type=script&lang=ts&external"
export * from "./settings.ts?vue&type=script&lang=ts&external"
import style0 from "./settings.scss?vue&type=style&index=0&id=f9f831f8&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9f831f8",
  null
  
)

/* custom blocks */
import block0 from "./settings.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fsettings%2Fsettings.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VTab,VTabs})
