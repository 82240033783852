import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import CompanySettingsView from '@/views/settings/company/company.vue';
import MyDataSettingsView from '@/views/settings/my-data/my-data.vue';
import SubscriptionsSettingsView from '@/views/settings/subscriptions/subscriptions.vue';
import InfoSettingsView from '@/views/settings/info/info.vue';
import { Route } from 'vue-router';
import store from '@/shared/store';
import { namespace } from 'vuex-class';

const name = 'settings-view';
const authModule = namespace('auth');
const logger = new Logger(name);

@Component({
  name: name,
  components: { CompanySettingsView, MyDataSettingsView, SubscriptionsSettingsView, InfoSettingsView },
})
export default class SettingsView extends Vue {
  @authModule.Action('updateHeaderNavData')
  private actionUpdateHeaderNavData: any;

  created() {
    this.setSettingsNavHeader();
  }

  setSettingsNavHeader() {
    this.actionUpdateHeaderNavData({ translateId: 'settings', to: '/settings/my-data' });
  }
  get isTopTabsVisible() {
    return !this.$route.fullPath.startsWith('/settings/company');
  }
  private key(name: string) {
    return name + this.$root.$i18n.locale;
  }
}
